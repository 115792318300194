<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">修改密码</h3>
          </div>
          <div class="box-body">
            <validation-observer v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(onSubmit)">
                <validation-provider tag="div" name="新密码" rules="required" v-slot="{ errors }">
                  <div :class="errors[0] ? 'form-group has-error' : 'form-group'">
                    <label>新密码</label>
                    <input v-model="form.password" type="text" class="form-control" placeholder="请输入密码" autocomplete="off" />
                    <span class="help-block">{{ errors[0] }}</span>
                  </div>
                </validation-provider>
                <validation-provider tag="div" name="再次密码" rules="required" v-slot="{ errors }">
                  <div :class="errors[0] ? 'form-group has-error' : 'form-group'">
                    <label>再次密码</label>
                    <input v-model="form.password_again" type="text" class="form-control" placeholder="请再次输入密码" autocomplete="off" />
                    <span class="help-block">{{ errors[0] }}</span>
                  </div>
                </validation-provider>
                <div class="form-group">
                  <h-button type="submit" color="primary" :disabled="submitIng">提交</h-button>
                </div>
              </form>
            </validation-observer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import qs from "qs";
import { apiAccountPasswordUpdate } from "@/apis/sys.api";
export default {
  components: {},
  data() {
    return {
      user: {},
      auth: {},
      params: {},
      query: {},
      loading: false,
      submitIng: false,
      form: {
        password: "",
        password_again: "",
      },
    };
  },
  computed: mapState({
    config: (state) => state.config,
  }),
  mounted() {
    const _this = this;
    _this.query = Object.assign(_this.query, _this.$route.query);
    _this.params = Object.assign(_this.params, _this.$route.params);
    _this.authInit(_this).then((res) => {
      _this.user = res.user;
      _this.auth = res.auth;
    });
  },
  methods: {
    ...mapActions(["authInit"]),
    onSubmit() {
      const _this = this;
      _this.submitIng = true;
      apiAccountPasswordUpdate(_this.form)
        .then(() => {
          _this.submitIng = false;
          _this.$Message({
            type: "success",
            text: "操作成功",
          });
        })
        .catch((err) => {
          _this.submitIng = false;
          _this.$Message({
            type: "error",
            text: err.message,
          });
        });
    },
  },
};
</script>
