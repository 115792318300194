<template>
  <header class="main-header">
    <router-link :to="config.homePage" class="logo">
      <span class="logo-mini">
        <img class="logo-img" :src="system.logo" alt="logo">
      </span>
      <span class="logo-lg">
        <img class="logo-img" :src="system.logo" alt="logo">
        <span class="logo-name">{{ system.name }}</span>
      </span>
    </router-link>
    <nav class="navbar navbar-static-top">
      <a href="#" class="sidebar-toggle" data-toggle="push-menu" role="button">
        <span class="sr-only"></span>
      </a>
      <div class="navbar-header">
        <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-collapse">
          <i class="fa fa-bars"></i>
        </button>
      </div>

      <div class="collapse navbar-collapse pull-left" id="navbar-collapse">
        <ul class="nav navbar-nav">
          <li v-if="currentPage"><router-link :to="currentPage.url">{{ currentPage.title }}</router-link></li>
        </ul>
      </div>
      <div class="navbar-custom-menu">
        <ul class="nav navbar-nav">
          <li class="dropdown user user-menu">
            <a href="#" class="dropdown-toggle" data-toggle="dropdown">
              <img src="/static/image/avatar.jpg" class="user-image" alt="User avatar">
              <span class="hidden-xs">{{  user.name  }}</span>
            </a>
            <ul class="dropdown-menu">
              <li class="user-header">
                <img src="/static/image/avatar.jpg" class="img-circle" alt="User avatar">
                <p>
                  {{  user.name  }}
                  <small>{{ user.role_name }}</small>
                </p>
              </li>
              <li class="user-footer">
                <div class="pull-left">
                  <router-link to="/web/password" class="btn btn-default btn-flat">修改密码</router-link>
                </div>
                <div class="pull-right">
                  <a href="javascript:void(0);" @click="logout" class="btn btn-default btn-flat">退出登录</a>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>
  
<script>
import { apiAccountLogout } from "@/apis/sys.api";

import {mapState} from "vuex";
export default {
  components: {},
  computed: mapState({
    config: (state) => state.config,
    user: (state) => state.authorize.user,
    system: (state) => state.authorize.system,
    currentPage: (state) => state.currentPage,
  }),
  methods: {
    logout() {
      const _this = this;
      _this.$Confirm('是否执行当前操作？', '操作提示').then(() => {
        apiAccountLogout()
          .then(res => {
            _this.$localStorage.remove("session");
            _this.$localStorage.remove("authorize");
            _this.$router.replace({ path: _this.config.loginPage });
          })
          .catch(err => {
            _this.$Message({
              type: 'error',
              text: err.message,
            });
          })
      }).catch(() => {});
    }
  }
}
</script>

<style scoped>
.main-header .logo .logo-lg {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
}

.main-header .logo .logo-lg img.logo-img {
  width: 36px;
  height: 36px;
  max-width: 36px;
  margin: 0;
}

.main-header .logo .logo-lg .logo-name {
  padding-left: 10px;
}

.main-header .logo .logo-mini img.logo-img {
  width: 36px;
  height: 36px;
  max-width: 36px;
}
</style>
